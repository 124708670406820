@font-face {
  font-family: 'ClashGrotesk-Regular';
  src: url('../fonts/ClashGrotesk-Regular.woff2') format('woff2'),
       url('../fonts/ClashGrotesk-Regular.woff') format('woff'),
       url('../fonts/ClashGrotesk-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Medium';
  src: url('../fonts/ClashGrotesk-Medium.woff2') format('woff2'),
       url('../fonts/ClashGrotesk-Medium.woff') format('woff'),
       url('../fonts/ClashGrotesk-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

